import React from 'react';
import { motion } from 'framer-motion';

import { removePopup } from '../../../../actions/popup';

import styles from './VideoPopup.module.css';
import LazyImage from '../../LazyImage';
import { useAppDispatch, useAppSelector } from '../../../../hooks';

const VideoPopup = ({}) => {
  const dispatch = useAppDispatch();
  const link = useAppSelector((state) => state.popup.data);
  return (
    <div className={`${styles.iPopup} iPopup`}>
      <motion.div
        className={styles.videoContainer}
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
      >
        <LazyImage
          radius="50%"
          className={styles.close}
          onClick={() => dispatch(removePopup())}
          src="/images/icons/cross-24px.svg"
          alt=""
        />
        <iframe
          width="100%"
          src={link}
          style={{ backgroundColor: '#fff' }}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </motion.div>
    </div>
  );
};

export default VideoPopup;
