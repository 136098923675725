import React from 'react';
import { Typography } from '@mui/material';

import Link from '../ActiveLink';

import data from '../../../content/global/footer/footer.json';
import LazyImage from '../LazyImage';
import Image from 'next/image';

import style from './style';

const Footer = ({}) => {
  const classes = style();

  const dropdownToggel = (e) => {
    e.currentTarget.classList.toggle('footerLinksToggle');
    const panel = e.currentTarget.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  };

  return (
    <div className={`${classes.footerSection}`}>
      <div className={classes.gridContainer}>
        <div className={classes.gridItem}>
          <div className={`${classes.logoSection}`}>
            <div className={classes.img}>
              <LazyImage src={`/images/icons/novatrox.svg`} alt="Novatr Logo" />
            </div>

            <Typography variant="subtitle2" component="p" paragraph>
              Dare to Disrupt
            </Typography>
          </div>

          <div className={`${classes.wraperMd}`}>
            <div>
              <Typography variant="body1" paragraph>
                Connect with us on :
              </Typography>

              <div className={`${classes.socialMd}`}>
                {data.social.map((val, key) => (
                  <a
                    key={key}
                    href={val.socialLink}
                    target="_blank"
                    rel="noreferrer"
                    title={`Novatr ${val.title}`}
                  >
                    <LazyImage
                      src={`/images/icons/social/${val.socialIcon}-white.svg`}
                      alt=""
                    />
                  </a>
                ))}
              </div>
            </div>

            <div>
              <div
                className={`${classes.toggleContainer}`}
                onClick={dropdownToggel}
              >
                <Typography variant="body1">Company</Typography>
                <LazyImage src="/images/icons/arrow-right-wh.svg" alt="" />
              </div>
              <div className={`${classes.footerLinks}`}>
                <ul>
                  {data?.company.map((val, key) => (
                    <Link href={val.link} key={key}>
                      <li>
                        <Typography variant="body2" component="span">
                          {val.linkTxt}
                        </Typography>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>

            <div className={`${classes.borderB}`}>
              <div
                className={`${classes.toggleContainer}`}
                onClick={dropdownToggel}
              >
                <Typography variant="body1">Tribe</Typography>

                <LazyImage src="/images/icons/arrow-right-wh.svg" alt="" />
              </div>
              <div className={`${classes.footerLinks}`}>
                <ul className={`${classes.limitLink}`}>
                  {data?.community.map((val, key) => (
                    <Link href={val.link} key={key}>
                      <li>
                        <Typography variant="body2" component="span">
                          {val.linkTxt}
                        </Typography>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>

            <div>
              <div
                className={`${classes.toggleContainer}`}
                onClick={dropdownToggel}
              >
                <Typography variant="body1">Learning Experiences</Typography>

                <LazyImage src="/images/icons/arrow-right-wh.svg" alt="" />
              </div>
              <div className={`${classes.footerLinks}`}>
                <ul>
                  {data?.learningExperience.map((val, key) => (
                    <Link href={val.link} key={key}>
                      <li>
                        <Typography variant="body2" component="span">
                          {val.linkTxt}
                        </Typography>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${classes.footerEnd}`}>
        <Typography variant="caption" component="span">
          &copy; Oneistox Inc.
        </Typography>
      </div>
    </div>
  );
};

const sizes = {
  xs: [120, 21, false],
  xxl: [197, 34, false],
};

export default Footer;
