import React from 'react';
import { makeStyles } from '@mui/styles';
import PhoneInput from 'react-phone-input-2';

interface PhoneInputField {
  value: string;
  setInputValue: any;
  isDownloadSyllabus?: boolean;
  setCountryCode?: any;
  placeholder?: string;
  oldValue: any;
  disabled?: any;
}
const PhoneInputField = ({
  value,
  isDownloadSyllabus,
  setCountryCode,
  setInputValue,
  placeholder,
  oldValue,
  disabled,
}: PhoneInputField) => {
  const classes = style();

  return (
    <>
      <PhoneInput
        containerClass={`${classes.phoneInput} ${
          isDownloadSyllabus ? classes.inputStyleDownloadSyllabus : ''
        }`}
        inputStyle={{ height: '40px' }}
        placeholder={placeholder || 'Enter phone number'}
        value={value}
        disabled={disabled}
        inputProps={{ required: true }}
        onChange={(phone, e: any) => {
          setCountryCode && setCountryCode(`${e.countryCode.toUpperCase()}`);
          setInputValue({ ...oldValue, mobile: `+${phone}` });
        }}
        countryCodeEditable={false}
        enableSearch={true}
        country={'in'}
      />
    </>
  );
};

export default PhoneInputField;

const style = makeStyles((theme: any) => ({
  phoneInput: {
    height: '56px',
    width: '100%',
    '& input': {
      // width: '100%',
      height: '100% !important',
      paddingLeft: '66px !important',
    },

    '& > div > div': {
      width: '49px !important',
    },
  },
  inputStyleDownloadSyllabus: {
    height: '42px',
    marginTop: 16,
    color: 'black',
  },
}));
