import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  footerSection: {
    background: '#1c1c1c',
    padding: '0 16px',
    '& *': { color: '#fff' },
  },
  gridContainer: {
    display: 'flex',
    paddingTop: '45px',
    paddingBottom: '45px',
    justifyContent: 'center',
  },
  gridItem: { width: '100%', maxWidth: '1280px' },
  logoSection: {
    borderBottom: 'solid 0.5px #ffffff',
    '& img': { width: '120px ', marginBottom: '10px' },
  },

  address: { padding: '25px 0' },
  toggleContainer: {
    padding: '8px 0',
    borderTop: 'solid 0.5px #ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& img': {
      transform: 'rotate(90deg)',
      width: '15px ',
      marginRight: '10px',
      transition: '0.3s',
    },
  },

  footerLinksToggle: {
    borderBottom: 'solid 0.5px #ffffff',
    '& img': { transform: 'rotate(-90deg)' },
  },

  footerLinks: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.4s ease-out',
    '& ul': {
      padding: '16px 0',
      '& li': {
        listStyle: 'none',
        lineHeight: 1.66,
        cursor: 'pointer',
        textTransform: 'capitalize',
        '& a:hover': { color: '#fff' },
      },
    },
  },

  footerEnd: {
    margin: '0 -16px',
    height: '29px',
    backgroundColor: '#39393b',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialMd: {
    marginBottom: '15px',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 36px)',
    gridGap: '16px',
    '& a': {
      width: '36px',
      height: '36px',
      borderRadius: '4px',
      backgroundColor: 'rgba(159, 158, 163, 0.5)',
      transition: '0.3s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#6941C6',
      },
      '& img': { objectFit: 'contain ', width: '16px ', cursor: 'pointer' },
    },
  },

  wraperMd: { marginTop: '15px' },

  [theme.breakpoints.up('sm')]: {
    logoSection: { '& img': { width: '127px' } },
    footerSection: { padding: '0 24px' },
    toggleContainer: {
      display: 'block',
      border: 'none',
      '& img': { display: 'none' },
    },
    footerLinks: { maxHeight: '100%', '& ul': { padding: '0' } },

    limitLink: { ' & li:nth-child(n + 8)': { display: 'none' } },
    wraperMd: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      '& > div:first-child': { gridColumn: '1 / span 4' },
    },

    address: { paddingTop: '21px' },
    footerEnd: { height: '35px', margin: '0 -24px' },
    borderB: { borderBottom: 'none' },
  },
  [theme.breakpoints.up('md')]: {
    gridContainer: {
      paddingTop: '65px',
      paddingBottom: '65px',
    },
    logoSection: {
      paddingBottom: '15px',
      '& img': { width: '180px ' },
    },

    wraperMd: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      marginTop: '32px',
      '& > div:first-child': { gridColumn: '1 / span 2' },
    },
  },
}));

export default style;
