import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import Navbar from './Layouts/Navbar';
import Footer from '../components/Layouts/Footer';
import AuthenticationPopup from './Layouts/Popups/Authentication';
import VideoPopup from './Layouts/Popups/VideoPopup';
import WelcomePopup from './Layouts/Popups/WelcomePopup';

import Loader from '../components/Layouts/Loader';

import { authCheckStatus } from '../actions/auth';
import { removeLoader } from '../actions/loader';
import { setDeviceWidth } from '../actions/device';
import { useAppDispatch, useAppSelector } from '../hooks';
import VerifyEmailPopup from './Layouts/Popups/VerifyEmailPopup';
import VideoPopupWithCTA from './Layouts/Popups/VideoPopup/VideoPopupWithCTA';
import DashboardFooter from './Dashboard/DashboardFooter';

interface Props {
  page?: string;
  canonicalOverride?: string;
  children: React.ReactNode;
}

const Layout = ({ children, page, canonicalOverride }: Props) => {
  const loginDetails = useAppSelector((state) => state.auth);
  const loader = useAppSelector((state) => state.loader.loading);
  const activePopup = useAppSelector((state) => state.popup.activePopup);
  const device = useAppSelector((state) => state.device);
  const [canonicalUrl, setCanonicalUrl] = useState(null);

  const dispatch = useAppDispatch();

  const router = useRouter();

  useEffect(() => {
    const token = Cookies.get('token');
    token && !loginDetails.isAuthenticated && dispatch(authCheckStatus());

    loader && dispatch(removeLoader());
  }, []);

  useEffect(() => {
    if (window && screen && device.width === 0) {
      dispatch(setDeviceWidth(screen.width));
    }
  }, []);

  useEffect(() => {
    const handleSetIntervalAuth = () => {
      dispatch(authCheckStatus());
    };
    const timerId = setInterval(handleSetIntervalAuth, 24 * 60 * 60 * 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    if (canonicalOverride !== 'true') {
      if (router?.isReady) {
        if (router.asPath.split('/')[1] === 'blog-web') {
          /* AWS amplify is rewriting "blog-web" in the url to "blog". 
          So this is done to fix the canonical url from "blog-web" to "web" for SEO */
          setCanonicalUrl(
            `https://www.novatr.com/blog/${
              router.asPath.split('blog-web')[1].split('?')[0]
            }`,
          );
        } else {
          setCanonicalUrl(
            `https://www.novatr.com${router.asPath.split('?')[0]}`,
          );
        }
      }
    }
  }, [router?.isReady]);

  useEffect(() => {
    const userData = loginDetails?.userData;

    if (
      loginDetails.isAuthenticated !== null &&
      !loginDetails.isAuthenticated &&
      Boolean(localStorage.getItem('isFirstLogin'))
    ) {
      localStorage.removeItem('isFirstLogin');
    }

    const isCompanyDashboard = userData?.roles?.includes('HIRING_PARTNER');
    if (isCompanyDashboard) {
      if (localStorage.getItem('isFirstLogin') === null) {
        localStorage.setItem('isFirstLogin', 'false');
        router.push(`/hiring/applications`);
      }
    }
  }, [loginDetails.userData]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,initial-scale=1"
        />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_PROXY_2}></link>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="theme-color" content="#7F56D9" />
        <meta name="apple-mobile-web-app-title" content="Novatr" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@oneistox" />
        <meta data-hid="author" name="author" content="Novatr" />
        <meta
          data-hid="og:type"
          name="og:type"
          property="og:type"
          content="website"
        />
        <meta
          data-hid="og:site_name"
          name="og:site_name"
          property="og:site_name"
          content="Novatr"
        />
      </Head>
      <Navbar page={page} />
      <main className={page}>{children}</main>
      {!['Bag', 'Profile'].includes(page) && <Footer />}
      {page === 'Profile' ? <DashboardFooter /> : null}

      {loader && <Loader />}
      <AuthenticationPopup />
      {activePopup === 'VideoPopup' && <VideoPopup />}
      {activePopup === 'VideoPopupWithCTA' && <VideoPopupWithCTA />}
      {activePopup === 'WelcomePopup' && <WelcomePopup />}
      {activePopup === 'VerifyEmailPopup' && <VerifyEmailPopup open={true} />}
    </>
  );
};

export default Layout;
