import React from 'react';
import { motion } from 'framer-motion';

import { removePopup } from '../../../../actions/popup';

import styles from './VideoPopup.module.css';
import LazyImage from '../../LazyImage';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import PrimaryButton from '../../MaterialUi/PrimaryButton';
import { Router, useRouter } from 'next/router';

const VideoPopupWithCTA = ({}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const link = useAppSelector((state) => state.popup.data);
  return (
    <div className={`${styles.iPopup} iPopup`}>
      <motion.div
        className={styles.videoContainer}
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
      >
        <LazyImage
          radius="50%"
          className={styles.close}
          onClick={() => dispatch(removePopup())}
          src="/images/icons/cross-24px.svg"
          alt=""
        />
        <iframe
          width="100%"
          src={link}
          style={{ backgroundColor: '#fff' }}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <PrimaryButton
            onClick={() => {
              router.push('certified-courses/computational-design');
              dispatch(removePopup());
            }}
          >
            View Course
          </PrimaryButton>
        </div>
      </motion.div>
    </div>
  );
};

export default VideoPopupWithCTA;
