import { makeStyles } from '@mui/styles';

const style = makeStyles(() => ({
  modal: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // textAlign: 'center',
  },
  welcomeContainer: {
    maxWidth: 560,
    boxShadow: '0 20px 40px 0 rgba(255, 90, 54, 0.16)',
    border: 'solid 1px #c7c6cb',
    backgroundColor: '#ffffff',
    width: '88%',
    margin: 'auto',
    position: 'relative',
    borderRadius: '12px',
  },
}));

export default style;
