import React from 'react';
import LazyImage from './LazyImage';

const Loader = () => {
  return (
    <div className="page-loader">
      <div>
        <LazyImage src="/images/icons/loader.svg" alt="" />
      </div>
      <style jsx>{`
        .page-loader {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.9);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1301;
        }
      `}</style>
    </div>
  );
};

export default Loader;
