import React, { useState } from 'react';
import { Box, Fade, Modal, TextField, Typography } from '@mui/material';

import styles from './WelcomePopup.module.css';
import style from './style';

import { removePopup } from '../../../../actions/popup';
import { signupSteps } from '../../../../actions/auth';
import PrimaryButton from '../../MaterialUi/PrimaryButton';
import { useAppDispatch } from '../../../../hooks';
import PhoneInputField from '../../PhoneInput';

const WelcomePopup = ({}) => {
  const dispatch = useAppDispatch();
  const classes = style();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    step: 1,
  });

  const { firstName, lastName, mobile, step } = formData;

  const changeData = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const sendData = () => {
    if (!firstName && !lastName && !mobile) {
      return;
    }

    dispatch(signupSteps(firstName, lastName, mobile));

    dispatch(removePopup());
  };

  const stepChanger = (step) => setFormData({ ...formData, step });

  const formSubmit = (e, val) => {
    e.preventDefault();

    if (
      (val === 2 && firstName !== '' && lastName !== '') ||
      (val === 3 && mobile !== '')
    ) {
      setFormData({ ...formData, step: val });
    }
  };

  return (
    <>
      <Modal className={classes.modal} open={true}>
        <Fade in={true}>
          <div className={classes.welcomeContainer}>
            <Box px={3} pt={3}>
              <div className={`${styles.popupSliderContainer} `}>
                {/* name */}

                <div className={styles.stepsContainer}>
                  {step === 1 && (
                    <div className={styles.popupBox}>
                      <Typography variant="h4" gutterBottom>
                        Welcome to the tribe!
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        You are now part of the learners of Novatr!
                      </Typography>

                      <div>
                        <form onSubmit={(e) => formSubmit(e, 2)}>
                          {/* <div>
                            <input
                              type="text"
                              name="name"
                              className={styles.popinput}
                              placeholder="What's your name?"
                              onChange={(e) => changeData(e)}
                              value={name}
                            />
                          </div> */}

                          <TextField
                            type="text"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            margin="normal"
                            placeholder="Your first name"
                            onChange={(e) => changeData(e)}
                            value={firstName}
                          />
                          <TextField
                            type="text"
                            name="lastName"
                            variant="outlined"
                            required
                            fullWidth
                            margin="normal"
                            placeholder="Your last name"
                            onChange={(e) => changeData(e)}
                            value={lastName}
                          />

                          {/* <input type="submit" value="Continue" /> */}
                          <PrimaryButton size="large" type="submit">
                            Continue
                          </PrimaryButton>
                        </form>
                      </div>
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <Typography variant="h4" gutterBottom>
                        Almost done!
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Don't worry we won't spam you. This is used in case we
                        need to contact you to help you out!
                      </Typography>

                      <div>
                        <form action="" onSubmit={(e) => formSubmit(e, 3)}>
                          {/* <TextField
                          type="tel"
                          variant="outlined"
                          name="mobile"
                          fullWidth
                          required
                          margin="normal"
                          placeholder="What's your phone number??"
                          onChange={(e) => changeData(e)}
                          value={mobile}
                        /> */}

                          <Box sx={{ mt: 2, mb: 1 }}>
                            <PhoneInputField
                              value={formData.mobile}
                              setInputValue={setFormData}
                              oldValue={formData}
                            />
                          </Box>

                          {/* <div>
                            <input
                              type="tel"
                              name="mobile"
                              className={styles.popinput}
                              placeholder="What's your phone number??"
                              onChange={(e) => changeData(e)}
                              value={mobile}
                            />
                          </div> */}

                          {/* <input
                            type="submit"
                            value="Continue"
                            onClick={() => sendData()}
                          /> */}
                          <PrimaryButton
                            size="large"
                            type="submit"
                            onClick={() => sendData()}
                          >
                            Continue
                          </PrimaryButton>
                        </form>
                      </div>
                    </div>
                  )}

                  {/* <div className={styles.popupBox}>
                    <div className={`${styles.header} ${styles.completed}`}>
                      <h3> Hi, {name}! </h3>
                      <p>
                        Take the first step to explore the best learning
                        opportunities!
                      </p>
                    </div>
                    <div>
                      <form action="" onSubmit={(e) => formSubmit(e)}>
                        <Link href="/courses">
                          <input type="button" value="Go to my courses" />
                        </Link>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
            </Box>

            <div className={styles.popupFooter}>
              <div
                className={`${styles.processContainer} ${
                  step === 3 && styles.justifyCenter
                }`}
              >
                <div className={styles.dotsContainer}>
                  <div
                    className={`${styles.dot} ${step >= 1 && styles.active}`}
                    onClick={() => stepChanger(1)}
                  ></div>
                  <div
                    className={`${styles.dot} ${step >= 2 && styles.active}`}
                    onClick={() => stepChanger(2)}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default WelcomePopup;
