import { loaderSlice } from '../reducers/loader';

export const startLoader = () => async (dispatch) => {
  dispatch(loaderSlice.actions.loaderStart(null));

  setTimeout(() => dispatch(removeLoader()), 3000);
};

export const removeLoader = () => async (dispatch) => {
  dispatch(loaderSlice.actions.removeLoader(null));
};

export const removeAllLoader = () => async (dispatch) => {
  dispatch(loaderSlice.actions.removeLoader({ all: true }));
};
